/* Home.css */

:root { /* https://www.awwwards.com/sites/serio-verify#typography */
    --background-color: #49c5b6; /* #49c5b6 or #3AAFA9 https://www.awwwards.com/sites/serio-verify#typography*/
    --text-color: #FFFFFF;
    --primary-text-color: #17252A;
    --secondary-text-color : #9C9C9C;
    --dark-secondary: #27373A;
}

@font-face {
    font-family: 'FuturaNew';
    src: url('../../public/fonts/Futura/FuturaLT.woff') format('woff');
}


body {
    padding:0;
    margin:0;
    background-color: var(--background-color);}/* background-color: #0E0B16; */

h1, h2, h3, h4, h5, h6, p, .h1, .h2, .h3, .h4, .h5, .h6, .p {
    margin-top: 0;
    margin-bottom: 0;
}

a, .a{
    color: var(--background-color);
}

.first-block{
    margin-top: -85px;
    @media screen and (min-height: 648px) {
        min-height: calc(100vh); /* 85 px */
    }
    @media screen and (max-height: 648px) {
        min-height: 648px; /* 85 px for nav and 20 px for padding */
    }
    display: flex;
    align-items: center; /* Align items at the start (top) */
    position: relative;

    padding: 0 1.4rem; /* FIX */
}

.first-contain{
    text-align: left;
}

.second-block{
    align-items: center; /* Align items at the start (top) */
    width: 100%;
    box-sizing: border-box;

    padding: 6rem 1.4rem; /* FIX */
    font-weight: 300;
    font-size: 1.5rem;
    background: var(--text-color);
    color: rgba(0,0,0,.8);
    /*box-shadow: 0px 3px 1px -2px rgba(0,0,0,.2), 0px 2px 2px 0px rgba(0,0,0,.14), 0px 1px 5px 0px rgba(0,0,0,.12);*/
}

.third-block{
    align-items: center; /* Align items at the start (top) */
    width: 100%;
    box-sizing: border-box;

    padding: 6rem 1.4rem; /* FIX */
    font-weight: 300;
    font-size: 1.5rem;
    color: var(--text-color);
}

.fourth-block{
    align-items: center; /* Align items at the start (top) */
    width: 100%;
    box-sizing: border-box;

    padding: 6rem 1.4rem; /* FIX */
    font-weight: 300;
    font-size: 1.5rem;
    background: var(--text-color);
    color: rgba(0,0,0,.8);
    /*box-shadow: 0px 3px 1px -2px rgba(0,0,0,.2), 0px 2px 2px 0px rgba(0,0,0,.14), 0px 1px 5px 0px rgba(0,0,0,.12);*/
}

.fifth-block{
    align-items: center; /* Align items at the start (top) */
    width: 100%;
    box-sizing: border-box;

    padding: 6rem 1.4rem; /* FIX */
    font-weight: 300;
    font-size: 1.5rem;
    background: var(--text-color);
    color: rgba(0,0,0,.8);
    /*box-shadow: 0px 3px 1px -2px rgba(0,0,0,.2), 0px 2px 2px 0px rgba(0,0,0,.14), 0px 1px 5px 0px rgba(0,0,0,.12);*/
}

.home-container {
    /*text-transform: uppercase;*/
    font-family: 'FuturaNew', sans-serif;
    color: var(--text-color);

    display: flex;
    justify-content: left;
    padding: 0 calc((100vw - 1024px) / 2);
    align-items: center;
    font-size: 3.1rem;
    text-align: left;
    margin: auto;
    position: relative;
}

.text-primary {
    /*text-transform: uppercase;*/
    font-family: 'FuturaNew', sans-serif;
    color: var(--primary-text-color);

    display: flex;
    justify-content: left;
    padding: 0 calc((100vw - 1024px) / 2);
    font-size: 3.2rem;
    text-align: left;
    margin: auto;
    position: relative;
}
  
.hello-container {
    /*text-transform: uppercase;*/
    font-family: 'FuturaNew', sans-serif;
    color: var(--background-color);

    display: flex;
    justify-content: left;
    padding: 0 calc((100vw - 1024px) / 2) 1rem;
    align-items: center;
    font-size: 2rem;
    text-align: left;
    margin: auto;
    position: relative;
}

.hello-text {
    /*text-transform: uppercase;*/
    font-family: 'FuturaNew', sans-serif;
    color: var(--background-color);

    display: flex;
    justify-content: left;
    padding: 0 calc((100vw - 1024px) / 2);
    align-items: center;
    font-size: 1.48rem;
    text-align: left;
    margin: auto;
    position: relative;
}

.more-text{
    font-family: 'FuturaNew', sans-serif;
    color: var(--background-color);

    display: flex;
    justify-content: left;
    padding: 2rem calc((100vw - 1024px) / 2) 1rem;
    align-items: center;
    font-size: 1.48rem;
    text-align: left;
    margin: auto;
    position: relative;
}

.skills-container{
    padding: 0 calc((100vw - 1024px) / 2);
    display: flex;
    flex-wrap: wrap;
}

.skills-bubble{
    font-family: 'FuturaNew', sans-serif;
    justify-content: left;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    font-size: 1.48rem;
    background: var(--background-color);
    border-radius: 0px;
    color: var(--text-color);
    transition: background 0.3s ease;
    cursor: default;
    &:hover {
        background: var(--primary-text-color);
    }
}

.projects-container {
    /*text-transform: uppercase;*/
    font-family: 'FuturaNew', sans-serif;

    display: flex;
    justify-content: left;
    padding: 0 calc((100vw - 1024px) / 2) 1rem;
    align-items: center;
    font-size: 2rem;
    text-align: left;
    margin: auto;
    position: relative;
}

.projects-text{
    /*text-transform: uppercase;*/
    font-family: 'FuturaNew', sans-serif;

    display: flex;
    justify-content: left;
    padding: 0 calc((100vw - 1024px) / 2);
    align-items: center;
    font-size: 1.48rem;
    text-align: left;
    margin: auto;
    position: relative;
    }

.projects-bubble-container{
    display: flex;

    padding: 0 calc((100vw - 1024px) / 2);
    flex-wrap: wrap;
    perspective: 1000px;
    margin-top: .5rem;

    justify-content: left
}

.projects-bubble {
    font-family: 'FuturaNew', sans-serif;
    padding: 1rem;
    max-width: 450px; /* Adjust the size as needed */
    height: 280px; /* Adjust the size as needed */
    position: relative;
    display: flex;
}

.image-container {
    height: 100%;
    border-radius: 9px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zoom-img {
    transform-style: preserve-3d;
    transition: transform 0.3s;
    cursor: default;
    border-radius: 9px;
    object-fit: contain; /* Change to 'contain' for fitting the image */
    height: 100%; /* Added to ensure the image takes the full height */
    transition: transform 0.3s;
}

.image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--text-color); /* Set the text color */
    font-size: 1.5rem; /* Set the font size */
    font-weight: bold; /* Set the font weight */
    opacity: 0;
    transition: opacity 0.3s;
    cursor: default;
}

.image-container:hover .image-text {
    opacity: 1;
}

.image-container:hover .zoom-img {
    transform: scale(1.05);
    filter: brightness(30%);
}